.App {
  h1 {
    font-size: 5rem;
    font-weight: 300;
    font-family: "Cormorant";
    color: white;
    margin: 0;
    letter-spacing: -5px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
    font-family: "Cormorant";
    color: white;
    margin: 0;
    letter-spacing: -2px;
  }

  &-background {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 5.88%,
      black 5.88%,
      black 11.76%,
      transparent 11.76%
    );
  }

  &-logo {
    height: 60vh;
    margin-bottom: 5rem;
  }

  &-chevron {
    position: absolute;
    width: 2vw;
    bottom: 10%;
  }

  &-black {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    background-color: black;
    gap: 5rem;
    .headers {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2.5rem;
    }
    .social {
      display: flex;
      flex-direction: row;
      gap: 6rem;
    }

    .phone {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      flex-direction: row;
    }
  }

  .icon {
    height: 3rem;
  }

  &-footer {
    position: relative;
    display: flex;
    height: 25vh;
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 5.88%,
      black 5.88%,
      black 11.76%,
      transparent 11.76%
    );
  }

  &-address {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 29.4vw;
    background-color: white;
    position: absolute;
    right: 0;
    bottom: 0;
    gap: 1rem;
    .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    p {
      font-size: 1.5rem;
      font-family: "Cormorant";
      font-weight: 600;
      margin: 0;
      letter-spacing: -1px;
    }
  }

  &-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 29.4vw;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    gap: 1rem;
    text-align: center;
    .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    p {
      font-size: 1.5rem;
      font-family: "Cormorant";
      font-weight: 600;
      margin: 0;
      letter-spacing: -1px;
    }
  }
}
